import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: [
    './legal-notice.component.scss',
    '../../../terms-and-conditions/page/terms-and-conditions/terms-and-conditions.component.scss'
  ]
})
export class LegalNoticeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
