import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Insurance, Offer } from '../../../shared/models/price-object';
import { Quotation, QuotationFromApi } from '../../../shared/models/quotation';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {

  public request: Observable<Quotation>;
  public insurance: Insurance;

  constructor(private http: HttpClient) { }

  public requestInsurance(
    insurance: Insurance,
    offer: Offer,
    quotationUserInput: object,
    priceUserInput: object,
    selectedValuesIds: Array<string>,
    priceMonthly: boolean) {

    this.insurance = insurance;
    const offerIndex = insurance.priceObject.offers.indexOf(offer);
    this.request = this.http.post(`${insurance.baseUrl}/quotation`, {
      quotation_context: insurance.priceObject.quotationContext,
      quotation_user_input: quotationUserInput,
      price_user_input: priceUserInput,
      offer_index: offerIndex,
      selected_values_ids: selectedValuesIds,
      price_monthly:  priceMonthly,
    }).pipe(
      map((quotationFromAPI: QuotationFromApi) => new Quotation(quotationFromAPI))
    );
  }
}
