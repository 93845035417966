import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  public faqCategories = {
    'Mon tarif': [
      {
        question: 'Comment obtenir un devis d’assurance moto, scooter, quad ou piste ?',
        answer: `Pour obtenir une proposition d’assurance en quelques clics, il vous suffit de compléter notre formulaire en ligne !
        <br>
        Munissez-vous de votre carte grise, de votre permis de conduire et de votre relevé d’informations et <b>obtenez votre tarif en
        quelques minutes.</b>`
      },
      {
        question: 'Quelle est l’assurance minimum pour un 2 roues ?',
        answer: `La garantie responsabilité civile, appelée aussi assurance au tiers,
         est obligatoire. Elle couvre tous les dommages que vous
        (ou votre moto) pourriez causer à de tierces personnes. Mais en cas d’accident responsable, vous ne serez pas indemnisé de
        vos propres dommages, qu’ils soient matériels ou corporels.`
      },
      {
        question: 'Mon véhicule est absent de la liste sur le tarificateur, que dois-je faire ?',
        answer: `Munissez-vous des caractéristiques exactes de votre véhicule : marque, type, modèle,
        date de mise en circulation, cylindrée, puissance fiscale puis appelez-nous au : <a href="tel:0987046569">09.87.04.65.69</a>.
        <br>
        <b>Nous chercherons à vous apporter une solution.</b>`
      },
      {
        question: 'Combien de temps est valable mon devis ?',
        answer: `Chez AS2R, <b>un devis est valable 30 jours</b> à partir de sa date de réalisation.`
      },
      {
        question: 'Comment est calculé le prix de mon assurance 2-roues ?',
        answer: `Le tarif de votre assurance est calculé en fonction de votre profil et expérience, du type de véhicule assuré et du lieu de
        garage du véhicule.
        <br>
        <b>Ce tarif est donc personnalisé et sur-mesure.</b>`
      },
      {
        question: 'Quels sont les différents types de permis ?',
        answer: `Les permis 2-roues sont divisés en plusieurs catégories :
        <br/>
        <b>Le permis A1</b> permet de conduire une moto légère 125cm³ ou un 3 roues à moteur d’une puissance de 15 kilowatts
        maximum.
        <br/>
        <b>Le permis A</b> permet de conduire toutes les motos avec ou sans side-car et tous les 3 roues à moteur quelle que soit leur
        puissance.
        <br/>
        <b>Le permis A2</b> permet de conduire une moto d’une puissance n’excédant pas 35 kilowatts (47.5cv) ou un 3 roues d’une
        puissance maximale de 15 kilowatts. Une réforme concernant ce permis entre en vigueur le 1er mars 2020, cliquez
        <a href="https://www.april-moto.com/nouveau-permis-moto-a2-comment-ca-marche/">ici</a> pour en
        savoir plus.
        <br/>
        <i>Le permis B correspond au permis automobile.</i>
        <br/>
        Pour plus d’informations, vous pouvez consulter
        <a href="https://www.service-public.fr/particuliers/vosdroits/N530">le site officiel</a>`
      },
      {
        question: 'Je veux opter pour le paiement mensuel, quand mon compte sera-t-il prélevé ?',
        answer: `La date de prélèvement est le 5 de chaque mois.`
      },
      {
        question: 'Qu’est ce qu’un relevé d’informations en assurance moto ?',
        answer: `Le relevé d’information est un document officiel regroupant les informations concernant la conduite d’un assuré.
        <br>
        Il comporte entre autres : la date de souscription du contrat, les informations du conducteur désigné, le nombre et la nature
        des sinistres ainsi que le coefficient de bonus-malus.
        <br>
        Vous êtes client AS2R et vous souhaitez recevoir votre relevé d’information ? Connectez-vous sur votre espace
      personnel.`
      },
      {
        question: 'Si je prête ma moto, qui est responsable en cas de sinistre ?',
        answer: `Le taux de responsabilité d’un accident dépend des circonstances et non du conducteur.
        <br>
        Si votre contrat prévoit que vous êtes le seul conducteur du véhicule, le prêt ne peut être qu’exceptionnel.
        <br>
        En cas de sinistre, une franchise additionnelle  *  (nommée “franchise exclusive”) est appliquée si le conducteur du véhicule
        n’est pas le conducteur désigné sur le contrat.
        <br>
        * <i>Le montant de la franchise en cas de prêt est indiqué dans votre contrat, elle est cumulable avec la franchise en cas
        d’accident responsable.</i>`
      },
      {
        question: 'Mon passager est-il couvert en cas de sinistre ?',
        answer: `En cas d’accident, que vous en soyez responsable ou non, <b>votre passager est toujours assuré</b>, c’est la loi.
        <br>
        La garantie de responsabilité civile (obligatoire dans tout contrat) indemnise les dégâts corporels et matériels causés par le
        conducteur du véhicule ainsi que par ses passagers.`
      },
      {
        question: 'Assurez-vous dans les DROM-COM ?',
        answer: `AS2R distribue ses contrats uniquement en <b>France métropolitaine.</b>`
      },
    ],
    'Mon contrat': [
      {
        question: 'Comment accéder à mon espace personnel ?',
        answer: `Pour accéder à votre espace personnel et ainsi gérer vos contrats d’assurance vos contrats d’assurance AS2R,
        rendez-vous sur <a href="http://www.as2r.com">www.as2r.com</a>`
      },
      {
        question: 'Comment changer le mot de passe de mon espace perso ?',
        answer: `Vous avez oublié votre mot de passe ou vous souhaitez le changer ? <br>C’est très simple :<br>
        <br>
        <b>Etape 1</b> : Rendez-vous sur votre espace client
        <br>
        <b>Etape 2</b> : Cliquez sur “Première connexion ou mot de passe oublié ?”
        <br>
        <b>Etape 3</b> : Saisissez l’adresse mail associée à votre contrat AS2R
        <br>
        <i>Vous allez recevoir un email avec la procédure pour définir un nouveau mot de passe.</i>`
      },
      {
        question: 'Proposez-vous des contrats pour une durée temporaire ou au kilomètre ?',
        answer: `Munissez-vous des caractéristiques exactes de votre véhicule : marque, type,
        modèle, date de mise en circulation, cylindrée,
        puissance fiscale puis appelez-nous au : <a href="tel:0987046569">09.87.04.65.69</a>.
        <br>
        <b>Nous chercherons à vous apporter une solution.</b>`
      },
      {
        question: 'Qu’est qu’une franchise ?',
        answer: `Une franchise est le montant qu’il reste à la charge de l’assuré à la suite d’un sinistre responsable.
        <br>
        Vos franchises sont indiquées dans votre contrat.`
      },
      {
        question: 'Je n’ai pas reçu ma carte verte, que dois-je faire ?',
        answer: `La vérification par un gestionnaire des informations déclarées à la souscription
        suppose la réception de votre dossier complet
        (éléments demandés à la souscription).
        <br>
        Vous avez reçu une carte verte provisoire, profitez de sa validité pour compléter votre dossier.
        <br>
        Si toutefois vous venez de nous faire parvenir vos documents, notre délai d’engagement client est de 10 jours.`
      },
      {
        question: 'Comment obtenir un constat amiable d’accident ?',
        answer: `Le constat amiable est un document très important pour garantir la protection des intérêts
        des personnes impliquées. Il doit
        être complété avec le plus grand soin et le maximum de détails. Il servira aux assureurs des véhicules impliqués dans
        l’accident à déterminer les responsabilités et les indemnisations éventuelles.
        <br>
        Vous disposez de 5 jours ouvrés après l’accident pour nous adresser votre constat amiable (même si l’autre conducteur a
        refusé de le signer).
        <br>
        Pour télécharger votre constat amiable, connectez-vous sur votre espace personnel.`
      },
      {
        question: 'Je n’utilise pas ma moto, puis-je arrêter mon assurance ?',
        answer: `AS2R ne propose pas d’assurance type “garage”, vous pouvez cependant baisser vos garanties à la formule essentielle afin de
        diminuer votre cotisation d’assurance.`
      },
      {
        question: 'Je souhaite résilier mon contrat AS2R, comment faire ?',
        answer: `Vous vendez votre machine, vous souhaitez résilier votre contrat pour vous assurer chez AS2R : laissez vous guider.`
      },
    ],
    'Autre questions': [
      {
        question: 'Je souhaite des informations sur la loi Hamon ?',
        answer: `Cette démarche permet à tout moment la résiliation de votre contrat après un an d’engagement.
        <br>
        <b>ATTENTION</b> : cette demande doit être faîte par votre nouvel assureur et ne concerne pas les contrats professionnels.
        <br>
        La nouvelle compagnie d’assurance s’occupe de tout. Vous n’avez plus qu’à vous laisser guider. Un mois plus tard, l’ancien
        contrat est rompu, tandis que le nouveau débute.`
      },
      {
        question: 'Je souhaite des informations sur la loi Chatel ?',
        answer: `La Loi Chatel permet d’éviter la reconduction automatique de vos contrats !
        <br>
        Lors de la réception de votre avis d’échéance, vous disposez d’un délai de 20 jours pour demander la résiliation de votre
        contrat.`
      },
      {
        question: 'Quels sont les moyens de protections agréés SRA ?',
        answer: `Le classement en “classe SRA” englobe les systèmes de protection électronique (coupe circuit, alarme…) et les systèmes de
        protection mécanique (U, chaîne et en complément le bloque disque).
        <br>
        Pour plus d’informations, rendez-vous sur le site officiel du SRA : <a href="http://www.sra.asso.fr">www.sra.asso.fr</a>`
      },
      {
        question: 'Qu’est que le bridage d’un véhicule ?',
        answer: `Le bridage d’un véhicule est le fait de limiter sa puissance ou sa vitesse.
        Depuis la réforme du 3 juin 2016, le délai probatoire du permis A2 s’est généralisé à tous les candidats au permis moto. Tous
        ces nouveaux titulaires, doivent donc rouler avec une moto compatible A2 d’origine, ou rendue compatible après bridage.`
      },
      {
        question: 'Comment savoir si ma moto est bridée A2/MTT1 ?',
        answer: `Une fois bridée A2, une moto doit répondre à trois conditions :
        <br>
        <br>
        1. Être bridée à 35 Kw (soit 47.5 cv) pour les motos vendues après janvier 2013 OU être bridée à 25 KW (soit 34 cv)
        pour les motos conçues avant la réforme A2 de janvier 2013.
        <br>
        2. Le rapport poids/puissance ne doit donc pas excéder 0,2 kW par kilogramme.
        <br>
        3. La puissance ne peut pas résulter du bridage d’un véhicule développant plus de 70 kw (soit 95 ch). Par exemple, une
        Kawasaki Z750 ou Triumph Street Triple de 106 chevaux, bridées en 25 Kw (34ch) selon l’ancienne réglementation,
        ne sont donc pas éligibles.`
      }
    ]
  };
  public  objectKeys = Object.keys;
  constructor() { }

  ngOnInit() {
  }

}
