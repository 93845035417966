import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { faChevronLeft, faChevronRight, faForward } from '@fortawesome/free-solid-svg-icons';
import Swal from "sweetalert2";

const fadeOutTimeSeconds = 0.25;
const pauseTimeSeconds = 0.25;
const slideTimeSeconds = 5;
@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({opacity: 0}),
            animate(`${fadeOutTimeSeconds}s ease-out`,
              style({opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1}),
            animate(`${fadeOutTimeSeconds}s ease-out`,
              style({opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class MainPageComponent implements OnInit, OnDestroy {

  public faForward = faForward;
  public faChevronLeft = faChevronLeft;
  public faChevronRight = faChevronRight;

  constructor() { }

  public redirect(pagename: string) {
      document.location.href = pagename;
  }

  private carouselInterval: number;
  public shownIndex = 0;
  public carouselItems: { imageClass: string, imageOffset: string, text: string, description: string}[] = [
    {
      imageClass: 'team',
      imageOffset: '87%',
      text: `UN SERVICE CLIENT
      HUMAIN ET <b>DISPONIBLE
      24H/24 ET 7J/7</b>`,
      description: `Notre équipe, basée à Louveciennes, vous
      connaît et sait vous accompagner
      dans toutes vos démarches`
    },
    {
      imageClass: 'all',
      imageOffset: '41%',
      text: `POUR <b>TOUS LES
      MOTARDS</b> ET
      LES DEUX-ROUES`,
      description: `Moto, scooter, cyclo, quad, moto verte... Nous
      assurons tous les 2-roues`
    },
    {
      imageClass: 'indemnisation',
      imageOffset: '74%',
      text: `JUSQU'À <b>1,2 MILLION
      D'EUROS
      INDEMNISÉS</b>`,
      description: `En cas d'accident corporel responsable,
      bénéficiez de la meilleure Protection Corporelle
      du Conducteur du marché`
    },
    {
      imageClass: 'track',
      imageOffset: '55%',
      text: `<b>PAS D'AVANCE
      D'ARGENT</b> EN CAS
      DE SINISTRE`,
      description: `En cas de sinistre, nous prenons en charge vos
      réparations sans avoir à avancer l'argent au
      réparateur`
    },
  ];

  ngOnInit() {
    this.createInterval();
  }

  ngOnDestroy(): void {
    this.clearInterval();
  }

  private createInterval() {
    this.carouselInterval = window.setInterval(() => {
      this._nextCarouselItem();
    }, slideTimeSeconds * 1000);
  }

  private clearInterval() {
    window.clearInterval(this.carouselInterval);
  }

  private resetInterval() {
    this.clearInterval();
    this.createInterval();
  }

  private _nextCarouselItem() {
    const previousIndex = this.shownIndex;
    this.shownIndex = null;
    window.setTimeout(() => {
      if (previousIndex + 1 === this.carouselItems.length) {
        this.shownIndex = 0;
      } else {
        this.shownIndex = previousIndex + 1;
      }
    }, (fadeOutTimeSeconds + pauseTimeSeconds) *  1000);
  }

  private _previousCarouselItem() {
    const previousIndex = this.shownIndex;
    this.shownIndex = null;
    window.setTimeout(() => {
      if (previousIndex === 0) {
        this.shownIndex = this.carouselItems.length - 1;
      } else {
        this.shownIndex = previousIndex - 1;
      }
    }, (fadeOutTimeSeconds + pauseTimeSeconds) *  1000);
  }

  public nextCarouselItem() {
    if (this.shownIndex === null) { return; }
    this.resetInterval();
    this._nextCarouselItem();
  }

  public previousCarouselItem() {
    if (this.shownIndex === null) { return; }
    this.resetInterval();
    this._previousCarouselItem();
  }
}
