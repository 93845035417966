import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { faCheck, faChevronDown, faQuestionCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFrown } from '@fortawesome/free-regular-svg-icons';
import { Subscription } from 'rxjs';
import { QuotationService } from 'src/app/modules/quotation/services/quotation.service';
import { QuotationData } from 'src/app/shared/components/quotation-dialog/quotation-data';
import { QuotationDialogComponent } from 'src/app/shared/components/quotation-dialog/quotation-dialog.component';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { Insurance, InsurancePriceInformation, Offer, OptionLabel } from '../../../../shared/models/price-object';
import { ColorsService } from '../../services/colors.service';
import { PricesService } from '../../services/prices.service';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
})
export class PriceComponent {

  constructor(
    private priceService: PricesService,
    private local: LocalStorageService,
    private color: ColorsService,
    private dialog: MatDialog,
    private router: Router,
    private quotationService: QuotationService,
    ) {}


  public showPriceMonthly = true;
  public pricingRequested = false;
  public insurances: Array<Insurance> = [];
  public optionIncludedIcon = faCheck;
  public optionNotIncludedIcon = faTimes;
  public dropDownIcon = faChevronDown;
  public helpIcon = faQuestionCircle;
  public sadIcon = faFrown;
  private subscriptions: Array<Subscription>;


  public completedCount = 0;
  public errorCount = 0;
  public pendingCount = 0;
  public totalCount = 0;

  public insuranceDisplayInformation: {[insuranceLabel: string]: {
    detailsShown: boolean,
    optionsFormGroup: FormGroup,
    offersFormControl: FormControl,
  }} = {};


  public getPrices() {
    this.pricingRequested = true;
    this.pendingCount = 0;
    this.completedCount = 0;
    this.errorCount = 0;

    this.priceService.priceObjects.forEach((insurance) => {
      this.totalCount++;
      this.pendingCount++;

      insurance.priceRequest.subscribe(
        insurerFromApi => {
          this.pendingCount--;
          this.completedCount++;
          insurance.priceObject = new InsurancePriceInformation(insurerFromApi);
          this.initializeDetails(insurance);
        },
        (error: HttpErrorResponse) => {
          this.pendingCount--;
          this.errorCount++;
          insurance.error = error.error.message;
        }
      );
    });
    this.insurances = this.priceService.priceObjects;
  }

  private initializeDetails(insurance: Insurance) {
    const controls: {[optionName: string]: FormGroup} = {};

    for (const optionInformation of insurance.priceObject.optionsLabels) {
      controls[optionInformation.text] = new FormGroup({
        optionIsSelected: new FormControl(false),
        selectedValue: new FormControl({value: optionInformation.valueLabels[0].name, disabled: true})
      });

      controls[optionInformation.text].get('optionIsSelected').valueChanges.subscribe(optionSelected => {
        if (optionSelected) {
          controls[optionInformation.text].get('selectedValue').enable();
        } else {
          controls[optionInformation.text].get('selectedValue').disable();
        }
      });
    }
    this.insuranceDisplayInformation[insurance.label] = {
      optionsFormGroup: new FormGroup(controls),
      offersFormControl: new FormControl(null, [Validators.required]),
      detailsShown: false,
    };
  }

  private getBodyFromLocalStorage() {
    return this.local.get(this.priceService.localStorageBodyKey);
  }

  public getBodyAsBase64() {
    return btoa(JSON.stringify(this.getBodyFromLocalStorage()));
  }

  public getPriceMonthlyOrYearly(priceMonth: number, priceYear: number) {
    return (this.showPriceMonthly ? priceMonth : priceYear);
  }

  public getPriceFrequency() {
    return (this.showPriceMonthly ? '/ mois' : '/ an');
  }


  public getPriceColor(offerIndex: number, priceObject: Insurance) {
    return this.color.colorAtIndex(offerIndex, priceObject.priceObject.offers.length, priceObject.colorStart, priceObject.colorEnd);
  }

  public getOptionFormControl(insurance: Insurance, optionName: string) {
    return this.insuranceDisplayInformation[insurance.label].optionsFormGroup.get(optionName);
  }

  public getSelectedValueName(insurance: Insurance, optionName: string) {
    return (this.getOptionFormControl(insurance, optionName) as FormGroup).controls.selectedValue.value;
  }

  public getTooltip(insurance: Insurance, optionLabel: OptionLabel) {
    const description = optionLabel.getValueFromValueLabel(
      this.getOptionFormControl(insurance, optionLabel.text).get('selectedValue').value
    ).description;
    return (description !== null ? description : 'Consultez le site de l\'assureur');
  }

  public getOptionPrice(insurance: Insurance, offer: Offer, optionName: string) {
    if (!this.optionIsIncludedInOffer(insurance, offer, optionName)) {
      return 0;
    }
    const value = offer.getOptionByOptionName(optionName).getValueByName(this.getSelectedValueName(insurance, optionName));
    return this.getPriceMonthlyOrYearly(value.priceMonth, value.priceYear);
  }

  public optionIsEnabled(insurance: Insurance, optionName: string) {
    return this.getOptionFormControl(insurance, optionName).get('optionIsSelected').value;
  }

  public optionIsIncludedInOffer(insurance: Insurance, offer: Offer, optionName: string) {
    const selectedOptionName = this.getSelectedValueName(insurance, optionName);
    return offer.getOptionByOptionName(optionName).getValueByName(selectedOptionName) !== undefined;
  }

  public formatPrice(price: number) {
    return Math.round(price * 100) / 100;
  }

  public getPriceOffer(insurance: Insurance, offer: Offer) {
    let price = this.getPriceMonthlyOrYearly(offer.priceMonth, offer.priceYear);
    for (const optionInformation of insurance.priceObject.optionsLabels) {
      if (
        this.optionIsEnabled(insurance, optionInformation.text) &&
        this.optionIsIncludedInOffer(insurance, offer, optionInformation.text)
      ) {
        price += this.getOptionPrice(insurance, offer, optionInformation.text);
      }
    }
    return this.formatPrice(price);
  }

  private getSelectedOffer(insurance: Insurance): Offer {
    return insurance.priceObject.offers[this.insuranceDisplayInformation[insurance.label].offersFormControl.value];
  }

  private getSelectedValuesIds(insurance: Insurance) {
    const selectedValuesIds = [];
    for (const optionName of Object.keys(this.insuranceDisplayInformation[insurance.label].optionsFormGroup.value)) {
      const optionFormGroup = this.insuranceDisplayInformation[insurance.label].optionsFormGroup.value[optionName];
      if (optionFormGroup.optionIsSelected && this.optionIsIncludedInOffer(insurance, this.getSelectedOffer(insurance), optionName)) {
        const option = insurance.priceObject.getOptionLabelFromOptionName(optionName);
        selectedValuesIds.push(option.getValueFromValueLabel(optionFormGroup.selectedValue).id);
      }
    }
    return selectedValuesIds;
  }

  public onQuotationClick(insurance: Insurance) {
    const selectedOffer = this.getSelectedOffer(insurance);
    const selectedValuesIds = this.getSelectedValuesIds(insurance);
    this.dialog.open(QuotationDialogComponent, {
      data: {
        insurerName: insurance.label,
        insurerUrl: insurance.baseUrl,
        offer: selectedOffer,
        quotationContext: insurance.priceObject.quotationContext,
      } as QuotationData,
      panelClass: 'custom-overlay',
      maxHeight: '90vh'
    })
    .afterClosed().subscribe((quotationUserInput: object | null) => {
      if (quotationUserInput !== null) {
        this.quotationService.requestInsurance(
          insurance,
          selectedOffer,
          quotationUserInput,
          this.local.get(this.priceService.localStorageBodyKey),
          selectedValuesIds,
          this.showPriceMonthly,
        );
        this.router.navigateByUrl('/devis');
      }
    });
  }

  // MOCK


}
