import { POSTAL_CODE_REGEX } from './../../../../shared/others/constants/validation-regexes';
import { disableValidationIfControlIsFalse } from './../../../../shared/validators/disable-validation-if-control-is-true';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatSnackBarRef,
  MatVerticalStepper,
} from '@angular/material';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SnackbarPromptComponent } from 'src/app/shared/components/snackbar-prompt/snackbar-prompt.component';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { InsurersService } from '../../services/insurers.service';
import { PricesService } from '../../services/prices.service';
import { ModelAvailabilityComponent } from './components/model-availability/model-availability.component';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output()
  submitEvent: EventEmitter<void> = new EventEmitter();

  @ViewChild(MatVerticalStepper, { static: false })
  private stepper: MatVerticalStepper;

  @ViewChild(ModelAvailabilityComponent, { static: false })
  public modelAvailabilityComponent: ModelAvailabilityComponent;

  priceLoading = false;

  fakeDisplay = {};
  insuranceForm = new FormGroup({
    user: new FormGroup({
      birth_date: new FormControl(null, [Validators.required]),

      am: new FormGroup({}, [Validators.required]),
      a: new FormGroup({}, [Validators.required]),
      b: new FormGroup({}, [Validators.required]),
      a1: new FormGroup({}, [Validators.required]),
      a2: new FormGroup({}, [Validators.required]),
      postal_code: new FormControl(null, [
        Validators.required,
        Validators.pattern(POSTAL_CODE_REGEX),
      ]),
    }),

    vehicle: new FormGroup({
      model: new FormGroup({}, [Validators.required]),
      release_date: new FormControl(new Date(), [Validators.required]),
      usage: new FormControl(null, [Validators.required]),
      acquisition_date: new FormControl(null, [Validators.required]),
      acquisition_method: new FormControl(null, [Validators.required]),
      garage_type: new FormControl(null, [Validators.required]),
      garage_location: new FormControl(null, [
        Validators.required,
        Validators.pattern(POSTAL_CODE_REGEX),
      ]),
      already_insured: new FormGroup({}, [Validators.required]),
    }),

    past: new FormGroup({
      license_suspension: new FormGroup({}, [Validators.required]),
      contract_termination: new FormGroup({}, [Validators.required]),
      accidents: new FormGroup({}, [Validators.required]),
    }),

    insurance_history: new FormGroup({
      already_insured_bike: new FormGroup({}, [Validators.required]),
      already_insured_car: new FormGroup({}, [Validators.required]),
      hamon: new FormGroup({}),
      bike_bonus: new FormGroup({}, [Validators.required]),
      car_bonus: new FormGroup({}, [Validators.required]),
    }),
  });

  resumeSnackbar: MatSnackBarRef<SnackbarPromptComponent>;

  bikeInsurers: Array<string> = [];
  carInsurers: Array<string> = [];

  constructor(
    private priceService: PricesService,
    private insurersService: InsurersService,
    private local: LocalStorageService,
    private snackbar: SnackBarService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    merge(
      this.insurersService.getBikeInsurers().pipe(
        tap((insurers: Array<string>) => {
          this.bikeInsurers = insurers;
        })
      ),
      this.insurersService.getCarInsurers().pipe(
        tap((insurers: Array<string>) => {
          this.carInsurers = insurers;
        })
      )
    ).subscribe();

    if (this.local.keyExists(this.priceService.localStorageBodyKey)) {
      this.resumeSnackbar = this.snackbar.prompt(
        'Voulez-vous récupérer les informations précédemment entrées?'
      );
      this.resumeSnackbar.onAction().subscribe(() => {
        this.retrieve();
      });
    }

    // this.insuranceForm.valueChanges.subscribe(() => console.log(this.insuranceForm))
  }

  ngOnDestroy(): void {
    if (this.resumeSnackbar) {
      this.resumeSnackbar.dismiss();
    }
  }

  ngAfterViewInit(): void {
    const valueChangeSubscription = this.insuranceForm.valueChanges.subscribe(
      (change) => {
        if (this.resumeSnackbar) {
          this.resumeSnackbar.dismiss();
        }
        valueChangeSubscription.unsubscribe();
      }
    );
  }
  public onSubmit() {
    const body = this.insuranceForm.value;
    this.priceService.savePriceRequestInfo(body);
    this.submitEvent.emit();
  }

  public retrieve() {
    const localValue = this.local.get(this.priceService.localStorageBodyKey);
    this.insuranceForm.reset();
    this.insuranceForm.patchValue(localValue);

    while (this.stepper.selectedIndex < this.stepper._steps.length - 1) {
      this.stepper.next();
    }
  }
}
