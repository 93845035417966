import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Insurance } from 'src/app/shared/models/price-object';
import { Document, Quotation } from 'src/app/shared/models/quotation';
import { QuotationService } from './services/quotation.service';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit {

  quotation: Quotation;
  constructor(
    public quotationService: QuotationService,
    private router: Router,
    ) { }

  ngOnInit() {
    if (this.quotationService.request === undefined) {
      // this.quotationService.insurance = {...new Insurance(), label: 'debug' };
      this.router.navigateByUrl('/');
    }
    this.quotationService.request.subscribe(quotation => {
      this.quotation = quotation;
    });
  }

  public onInfoClick() {
    this.generatePdf(this.quotation.information);
  }

  public onQuotationClick() {
    this.generatePdf(this.quotation.quotation);
  }

  private generatePdf(pdfDocument: Document) {
    pdfDocument.generate();
  }

}
