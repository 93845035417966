import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { requiredIfConditionIsTrue } from '../../validators/required-if-parameter-is-true';

@Component({
  selector: 'app-accident',
  templateUrl: './accident.component.html',
  styleUrls: ['./accident.component.scss']
})
export class AccidentComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() formItemIndex: number;

  public accidentFormControls = {
    type: new FormControl(null, [Validators.required]),
    date: new FormControl(null, [Validators.required]),
    responsibility_rate: new FormControl(null, [requiredIfConditionIsTrue(this.showResponsibilityRateField())]),
    vehicle_is_car: new FormControl(null, [Validators.required] ),
    physical_injury: new FormControl(false, [Validators.required]),
  };

  private subscription: Subscription;


  ngOnInit() {
    Object.keys(this.accidentFormControls).forEach(controlName => {
      this.form.addControl(controlName, this.accidentFormControls[controlName]);
    });
    this.form.valueChanges.subscribe(change => {
    });
  }

  public showResponsibilityRateField() {
    if (this.form === undefined) {
      return false;
    }
    return [1, 2].includes(this.form.value.type);
  }
}
