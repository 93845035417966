import { NgModule } from '@angular/core';
import { GestureConfig } from '@angular/material';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComparatorModule } from './modules/comparator/comparator.module';
import { ComplaintsModule } from './modules/complaints/complaints.module';
import { ExceptionsModule } from './modules/exceptions/exceptions.module';
import { FaqModule } from './modules/faq/faq.module';
import { BlogModule } from './modules/blog/blog.module';
import { LegalNoticeModule } from './modules/legal-notice/legal-notice.module';
import { MainPageModule } from './modules/main-page/main-page/main-page.module';
import { PresentationModule } from './modules/presentation/presentation.module';
import { QuotationModule } from './modules/quotation/quotation.module';
import { TermsAndConditionsModule } from './modules/terms-and-conditions/terms-and-conditions.module';
import { SharedModule } from './shared/shared.module';
import { AdminModule } from './modules/admin/admin.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    ComparatorModule,
    ComplaintsModule,
    ExceptionsModule,
    BlogModule,
    FaqModule,
    LegalNoticeModule,
    AdminModule,
    MainPageModule,
    PresentationModule,
    QuotationModule,
    TermsAndConditionsModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
  ],
  providers: [
    {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
