import { CityComponent } from './components/city/city.component';
import { AlreadyInsuredBikeComponent } from './components/already-insured-bike/already-insured-bike.component';
import { AlreadyInsuredThisVehicleComponent } from './components/already-insured-this-vehicle/already-insured-this-vehicle.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDateFormats, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccidentComponent } from './components/accident/accident.component';
import { AccidentsComponent } from './components/accidents/accidents.component';
import { AlreadyInsuredComponent } from './components/already-insured/already-insured.component';
import { BonusComponent } from './components/bonus/bonus.component';
import { ContractTerminationComponent } from './components/contract-termination/contract-termination.component';
import { DateComponent } from './components/date/date.component';
import { InputComponent } from './components/input/input.component';
import { LicenceComponent } from './components/licence/licence.component';
import { LicenseSuspensionComponent } from './components/license-suspension/license-suspension.component';
import { ListSelectComponent } from './components/list-select/list-select.component';
import { ModelComponent } from './components/model/model.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { QuotationDialogComponent } from './components/quotation-dialog/quotation-dialog.component';
import { SnackbarPromptComponent } from './components/snackbar-prompt/snackbar-prompt.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { FooterComponent } from './footer/footer.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import {MatPaginatorModule} from '@angular/material/paginator';
import { HamonComponent } from './components/hamon/hamon.component';

export const FR_DATE_FORMAT: MatDateFormats = {
  parse: {
  dateInput: 'DD/MM/YYYY',
  },
  display: {
  dateInput: 'DD/MM/YYYY',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'DD/MM/YYYY',
  monthYearA11yLabel: 'MMMM YYYY',
  },
  };

const SHARED_MODULES = [
  FontAwesomeModule,
  FormsModule,
  HttpClientModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  ReactiveFormsModule,
  RouterModule,
];

const SHARED_DIALOGS = [
  SnackbarPromptComponent,
  QuotationDialogComponent,
];

const SHARED_COMPONENTS = [
  ...SHARED_DIALOGS,
  AccidentComponent,
  AccidentsComponent,
  AlreadyInsuredComponent,
  AlreadyInsuredBikeComponent,
  AlreadyInsuredThisVehicleComponent,
  BonusComponent,
  CityComponent,
  ContractTerminationComponent,
  DateComponent,
  FooterComponent,
  HamonComponent,
  InputComponent,
  LicenceComponent,
  LicenseSuspensionComponent,
  ListSelectComponent,
  ModelComponent,
  NavbarComponent,
  ToggleComponent,
];

@NgModule({
  declarations: [
    ...SHARED_COMPONENTS,
  ],
  imports: [
    ...SHARED_MODULES,
    CommonModule
  ],
  exports: [
    ...SHARED_MODULES,
    ...SHARED_COMPONENTS,
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: MAT_DATE_FORMATS, useValue: FR_DATE_FORMAT},
  ],
  entryComponents: [
    ...SHARED_DIALOGS,
  ]
})
export class SharedModule { }
