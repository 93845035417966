import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-accidents',
  templateUrl: './accidents.component.html',
  styleUrls: ['./accidents.component.scss']
})
export class AccidentsComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() formItem: string;

  public accidentsFormControls = {
    // had_accidents: new FormControl(false),
    count: new FormControl(0),
    accidents: new FormArray([
    ])
  };

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  private subscription: Subscription;

  ngOnInit(): void {
    Object.keys(this.accidentsFormControls).forEach(controlName => {
      this.form.addControl(controlName, this.accidentsFormControls[controlName]);
    });

    this.subscription = this.form.controls.count.valueChanges.subscribe((count: number) => {

      (this.form.controls.accidents as FormArray).clear();

      for ( let i = 0; i < count; i++) {
        (this.form.controls.accidents as FormArray).push(new FormGroup({}));
        this.changeDetectorRef.detectChanges();
      }
      this.form.updateValueAndValidity();
    });

    // disableValidationIfControlIsTrue(this.form, 'had_accidents');
    // this.form.controls.had_accidents.updateValueAndValidity();

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
