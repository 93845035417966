import { Observable, of } from 'rxjs';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { MyDateAdapter } from '../../others/adapters/date.adapter';
import { OptionsObject } from './options-object';
import {
  startWith,
  map,
  debounceTime,
  throttleTime,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MyDateAdapter }],
})
export class InputComponent implements OnInit {
  // Todo: Split into 1 component / type
  @ViewChild('datePickerInput', { static: false }) datePickerInput: any;

  public autoCompleteControl = new FormControl(null);
  public filteredOptions: Observable<string[]>;
  @Input() control: FormControl;
  @Input() group: FormGroup;
  @Input() label: string;
  @Input() type:
    | 'text'
    | 'disabledText'
    | 'password'
    | 'integer'
    | 'float'
    | 'slider'
    | 'select'
    | 'select-autocomplete'
    | 'selectValue'
    | 'date'
    | 'toggle'
    | 'option'
    | 'choice' = 'text';
  @Input() options: Array<string> | OptionsObject;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() placeholder: string;
  @Input()
  sliderDisplayFunction: (value: number) => number = (value: number) => value

  ngOnInit() {
    if (this.type === 'select-autocomplete') {
      this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(
        // startWith(''),
        debounceTime(100),
        tap((value) => {
          if (
            this.options !== null &&
            (this.options as Array<string>).includes(value)
          ) {
            this.control.setValue(value);
          }
        }),
        map((value) => this._filter(value))
      );

      this.autoCompleteControl.setValue(this.control.value);

      this.control.valueChanges.subscribe((value) => {
        this.autoCompleteControl.setValue(value, { emitEvent: false });
      });
    }
    if (this.type === 'date') {
      this.control.valueChanges.subscribe((value) => {
        if (
          this.datePickerInput.nativeElement.value.length >= 4 &&
          this.datePickerInput.nativeElement.value[2] !== '/'
        ) {
          this.datePickerInput.nativeElement.value = `${this.datePickerInput.nativeElement.value.slice(
            0,
            2
          )}/${this.datePickerInput.nativeElement.value.slice(2)}`;
        } else if (
          this.datePickerInput.nativeElement.value.length >= 6 &&
          this.datePickerInput.nativeElement.value[5] !== '/'
        ) {
          this.datePickerInput.nativeElement.value = `${this.datePickerInput.nativeElement.value.slice(
            0,
            5
          )}/${this.datePickerInput.nativeElement.value.slice(5)}`;
        }
      });
    }
  }

  private _filter(value: string): string[] {
    if (value === null) {
      return this.options as Array<string>;
    }
    const filterValue = value.toLowerCase();
    if (this.options === undefined) {
      return [];
    }
    return (this.options as Array<string>).filter(
      (option) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  public updateOptions() {
    console.log('here');
    console.log(this.control.value);
    this.autoCompleteControl.updateValueAndValidity({
      onlySelf: true,
      emitEvent: true,
    });
    this.autoCompleteControl.setValue(this.control.value);
  }
  // Keep order of the options for radio buttons
  public keepOriginalOrder = (a: any, b: any) => a.key;
}
