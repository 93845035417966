import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  constructor(private http: HttpClient) {}

  public getCityFromPostalCode(postalCode: string) {
    return this.http.get(`${environment.api}city/from-postal-code/${postalCode}/`).pipe(
      map((cityName: string) => cityName)
    );
  }
}
