import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  httpLogin(username: string, password: string) {
    return this.http.post(`${environment.api}admin/login`, {
      username,
      password,
    });
  }
}
