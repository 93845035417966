import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
  POSTAL_CODE_REGEX,
  PHONE_REGEX,
  EMAIL_REGEX,
  LICENCE_ID_REGEX,
  PLATE_REGEX,
  BROKER_ID_REGEX,
} from '../../others/constants/validation-regexes';
import { LocalStorageService } from '../../services/local-storage.service';
import { laterThanToday } from '../../validators/later-than-today.validator';
import { QuotationData } from './quotation-data';

const QUOTATION_LOCAL_KEY = 'quotation';
@Component({
  selector: 'app-quotation-dialog',
  templateUrl: './quotation-dialog.component.html',
  styleUrls: ['./quotation-dialog.component.scss'],
})
export class QuotationDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<QuotationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QuotationData,
    private local: LocalStorageService
  ) {}

  public quotationForm = new FormGroup({
    user: new FormGroup({
      already_client: new FormControl(null, [Validators.required]),
      gender: new FormControl(null, [Validators.required]),
      family_name: new FormControl(null, [Validators.required]),
      first_name: new FormControl('', [Validators.required]),
    }),
    contact_details: new FormGroup({
      address: new FormControl('', [Validators.required]),
      additional_address_1: new FormControl(''),
      additional_address_2: new FormControl(''),
      postal_code: new FormControl('', [
        Validators.required,
        Validators.pattern(POSTAL_CODE_REGEX),
      ]),
      city: new FormControl('', [Validators.required]),
      phone_number: new FormControl('', [
        Validators.required,
        Validators.pattern(PHONE_REGEX),
      ]),
      email_address: new FormControl('', [
        Validators.pattern(EMAIL_REGEX),
        Validators.required,
      ]),
    }),
    additional_information: new FormGroup({
      licence_number: new FormControl('', [
        Validators.pattern(LICENCE_ID_REGEX),
      ]),
      plate_number: new FormControl('', [Validators.pattern(PLATE_REGEX)]),
      start_date: new FormControl(null, [
        Validators.required,
        laterThanToday(),
      ]),
      broker_id: new FormControl('', [Validators.pattern(BROKER_ID_REGEX)]),
    }),
  });

  ngOnInit(): void {
    this.dialogRef
      .beforeClosed()
      .subscribe((quotationSubmitted: boolean | undefined) => {
        this.onClose(!!quotationSubmitted);
      });
    if (this.local.keyExists(QUOTATION_LOCAL_KEY)) {
      this.quotationForm.setValue(this.local.get(QUOTATION_LOCAL_KEY));
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.quotationForm.patchValue({
      additional_information: {
        start_date: today,
      },
    });
  }

  private onClose(quotationSubmitted: boolean) {
    this.saveFormToLocal();
    if (quotationSubmitted) {
      this.dialogRef.close(this.quotationForm.value);
    } else {
      this.dialogRef.close(null);
    }
  }

  public validateQuotation() {
    // Todo: validate quotation
    this.saveFormToLocal();
    this.onClose(true);
  }

  private saveFormToLocal() {
    this.local.set(QUOTATION_LOCAL_KEY, this.quotationForm.value);
  }
}
