import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-choosing-gear',
  templateUrl: './choosing-gear.component.html',
  styleUrls: ['./choosing-gear.component.scss', '../blog-style.scss']
})
export class ChoosingGearComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
