import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const requiredIfConditionIsTrue = (conditionThatMustBeTrue: boolean): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
        if (!conditionThatMustBeTrue) {
            return;
        }
        if (control.value != null) {
            return;
        }
        return {notValidated: true};
    };
};
