import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModelAvailabilityComponent } from './components/form/components/model-availability/model-availability.component';
import { FormComponent } from './components/form/form.component';
import { PriceComponent } from './components/price/price.component';
import { ComparatorComponent } from './page/comparator.component';

@NgModule({
  declarations: [
    ComparatorComponent,
    FormComponent,
    PriceComponent,
    ModelAvailabilityComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [ComparatorComponent]
})
export class ComparatorModule { }
