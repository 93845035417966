import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LegalNoticeComponent } from './page/legal-notice/legal-notice.component';

@NgModule({
  declarations: [LegalNoticeComponent],
  imports: [
    CommonModule
  ],
  exports: [
    LegalNoticeComponent
  ]
})
export class LegalNoticeModule { }
