import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    private loginService: LoginService,
    private authService: AuthService,
    private router: Router,
    ) {}

  public wrongCredentials = false;

  public loginForm = new FormGroup(
    {
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    }
  );

  public login() {
    this.loginService.httpLogin(this.loginForm.value.username, this.loginForm.value.password).subscribe(
      (response: {access_token: string}) => {
        this.authService.setToken(response.access_token);
        this.wrongCredentials = false;
        this.router.navigateByUrl('/admin/dashboard');
      },
      (error) => {
        this.wrongCredentials = true;
    });
  }
}
