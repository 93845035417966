import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { blogItems } from './blog-items';
import { FrontPageComponent } from './components/front-page/front-page.component';
import { BlogComponent } from './page/blog/blog.component';

const routes: Routes = [
  {
    path: 'blog',
    component: BlogComponent,
    children: ([
      {
        path: '',
        component: FrontPageComponent,
      },
    ] as Routes).concat(
      blogItems.filter(blogItem => blogItem.component !== null).map(blogItem => ({
        path: blogItem.route,
        component: blogItem.component,
      })),
      [{ path: '', redirectTo: '', pathMatch: 'full'}],
      [{ path: '**', redirectTo: ''}]
    )
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BlogRoutingModule { }
