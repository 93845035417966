export class TypesResponseObject {
    types: Array<string>;
}

export class BrandsResponseObject {
    brands: Array<string>;
}

export class CylinderSizesResponseObject {
    sizes: Array<string>;
}

export class ModelInfoResponseObject {
    brand: string;
    size: string;
    name: string;
}

export class ModelsResponseObject {
    'models_names': Array<string>;
}

export class ModelInfo {
    'brand_name': string;
    'cylinder_size': number;
    'model_name': string;
  }

