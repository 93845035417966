import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { SharedModule } from 'src/app/shared/shared.module';
import { ImageDialogComponent } from './components/image-dialog/image-dialog.component';
import { ExceptionsComponent } from './exceptions.component';

@NgModule({
  declarations: [ExceptionsComponent, ImageDialogComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgxJsonViewerModule,
  ],
  exports: [
    ExceptionsComponent,
  ],
  entryComponents: [ImageDialogComponent]
})
export class ExceptionsModule { }
