import { InputComponent } from './../input/input.component';
import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ModelsService } from 'src/app/modules/comparator/components/form/services/models.service';

@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss'],
})
export class ModelComponent implements OnInit, OnDestroy {
  ALL_TYPES_CHOICE = 'Tous';
  @Input() form: FormGroup;

  @ViewChild('brandsComponentRef', { static: false })
  brandsComponent: InputComponent;
  @ViewChild('modelComponentRef', { static: false })
  modelComponent: InputComponent;

  modelForm = {
    type: new FormControl(this.ALL_TYPES_CHOICE),
    brand_name: new FormControl(null, [Validators.required]),
    cylinder_size: new FormControl(null, [Validators.required]),
    model_name: new FormControl(null, [Validators.required]),
  };

  types: Array<string> = [];
  brands: Array<string>;
  cylinderSizes: Array<string>;

  modelsNames: Array<string>;
  modelsCodes: Array<string>;

  subscriptions: Array<Subscription>;

  constructor(
    private modelsService: ModelsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    Object.keys(this.modelForm).forEach((controlName) => {
      this.form.addControl(controlName, this.modelForm[controlName]);
    });

    this.modelsService.getTypes().subscribe((types) => {
      this.types = types;
    });

    this.modelsService.getBrands().subscribe((brands) => {
      this.brands = brands;
      this.route.queryParams.subscribe((_) =>
        this.updateVehicleTypeFromQueryParams()
      );
    });
    this.subscriptions = [
      this.form.controls.type.valueChanges.subscribe((type: string) => {
        console.log(type);
        if (type === null) {
          return;
        }
        this.modelsService
          .getBrands(this.getTypeIfDefined(type))
          .subscribe((brands) => {
            console.log(brands);
            this.brands = brands;
            if (this.brands === []) {
              return;
            }
            if (this.brands.length === 1) {
              this.form.patchValue({ brand_name: this.brands[0] });
              return;
            }
            this.form.patchValue({ brand_name: '' });
            this.form.patchValue({ cylinder_size: null });
            this.form.patchValue({ model_name: '' });
            this.brandsComponent.updateOptions();
          });
      }),

      this.form.controls.brand_name.valueChanges.subscribe(
        (brandName: string) => {
          if (brandName === null) {
            return;
          }
          this.getCylinderSizesForBrand(brandName).subscribe(() => {
            if (this.cylinderSizes === []) {
              return;
            }
            if (this.cylinderSizes.includes(this.form.value.cylinder_size)) {
              return;
            }
            if (this.cylinderSizes.length === 1) {
              this.form.patchValue({ cylinder_size: this.cylinderSizes[0] });
              return;
            }
            this.form.patchValue({ cylinder_size: null });
            this.form.patchValue({ model_name: null });
          });
        }
      ),

      this.form.controls.cylinder_size.valueChanges.subscribe(
        (cylinderSize: string) => {
          if (cylinderSize === null) {
            return;
          }
          this.getModelsForCylinderSize(cylinderSize).subscribe(() => {
            if (this.modelsNames === []) {
              return;
            }
            this.modelComponent.updateOptions();
            if (this.modelsNames.includes(this.form.value.model_name)) {
              return;
            }
            if (this.modelsNames.length === 1) {
              this.form.patchValue({ model_name: this.modelsNames[0] });
            }
            this.form.patchValue({ model_name: null });
          });
        }
      ),

      // this.form.controls.model.valueChanges.subscribe((modelName: string) => {
      //   if (modelName === null) { return; }
      //   // This field was filled manually
      //   // if (this.form.controls.model.touched) {
      //   this.form.patchValue({});
      //   // }
      //   // this.form.controls.model.markAsTouched();
      //   // this.control.patchValue({[this.formItem]: modelCode});
      // }),
    ];
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private updateVehicleTypeFromQueryParams() {
    const queryParamsVehicleType = this.route.snapshot.queryParamMap.get(
      'vehicleType'
    );
    if (queryParamsVehicleType !== null) {
      Object.keys(this.types).includes(queryParamsVehicleType.toUpperCase());
      this.form.patchValue({ type: queryParamsVehicleType.toUpperCase() });
      this.brandsComponent.updateOptions();
    }
  }

  public typesWithAllSelection() {
    return [this.ALL_TYPES_CHOICE, ...this.types];
  }

  private getCylinderSizesForBrand(brand: string) {
    return this.modelsService
      .getCylinderSizes(brand, this.getTypeIfDefined(this.form.value.type))
      .pipe(
        tap((cylinderSizes) => {
          this.cylinderSizes = cylinderSizes;
        })
      );
  }

  private getModelsForCylinderSize(cylinderSize: string) {
    return this.modelsService
      .getModels(
        this.form.value.brand_name,
        cylinderSize,
        this.getTypeIfDefined(this.form.value.type)
      )
      .pipe(
        tap((models) => {
          this.modelsNames = models.models_names;
        })
      );
  }

  private getTypeIfDefined(type: string) {
    return type === this.ALL_TYPES_CHOICE ? undefined : type;
  }
}
