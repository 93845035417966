import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { ComparatorComponent } from './modules/comparator/page/comparator.component';
// import { ComplaintsComponent } from './modules/complaints/page/complaints/complaints.component';
import { FaqComponent } from './modules/faq/page/faq/faq.component';
import { LegalNoticeComponent } from './modules/legal-notice/page/legal-notice/legal-notice.component';
import { MainPageComponent } from './modules/main-page/main-page/page/main-page/main-page.component';
import { PresentationComponent } from './modules/presentation/page/presentation/presentation.component';
import { QuotationComponent } from './modules/quotation/quotation.component';
import { TermsAndConditionsComponent } from './modules/terms-and-conditions/page/terms-and-conditions/terms-and-conditions.component';
// import {BlogComponent} from "@blog/page/blog/blog.component";
// import { BlogModule } from '@blog/blog.module';
// import { LoginComponent } from './modules/admin/pages/login/login.component';
// import { DashboardComponent } from './modules/admin/pages/dashboard/dashboard.component';
// import { AdminAuthGuard } from './shared/guards/admin.auth.guard';

const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
    pathMatch: 'full'
  },
  // {
  //   path: 'admin',
  //   children: [
  //     {
  //       path: 'login',
  //       component: LoginComponent
  //     },
  //     {
  //       path: 'dashboard',
  //       component: DashboardComponent,
  //       canActivate: [AdminAuthGuard],
  //     },
  //     {
  //       path: '**',
  //       redirectTo: 'dashboard',
  //       pathMatch: 'full',
  //     },
  //   ]
  // },
  // {
  //   path: 'comparateur',
  //   component: ComparatorComponent,
  //   pathMatch: 'full'
  // },
  {
    path: 'devis',
    component: QuotationComponent,
    pathMatch: 'full'
  },
  {
    path: 'faq',
    component: FaqComponent,
    pathMatch: 'full'
  },
  {
    path: 'presentation',
    component: PresentationComponent,
    pathMatch: 'full'
  },
  {
    path: 'cgu',
    component: TermsAndConditionsComponent,
    pathMatch: 'full'
  },
  {
    path: 'legal',
    component: LegalNoticeComponent,
    pathMatch: 'full'
  },
  // {
  //   path: 'complaints',
  //   component: ComplaintsComponent,
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'blog',
  //   loadChildren:  () => BlogModule,
  // },
  {
    path: 'blog',
    loadChildren: './modules/blog/blog.module#BlogModule'
  },

  // {
  //   path: 'blog',
  //   component: BlogComponent,
  //   pathMatch: 'full'
  // },

  // {
  //   path: 'blog', loadChildren: () => new Promise(resolve => {
  //     (require as any).ensure([], require => {
  //       resolve(require('./modules/blog/blog.module').BlogModule);
  //     })
  //   })
  // },


  {
    path: '**',
    redirectTo: '/',
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
