import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { disableValidationIfControlIsFalse } from '../../validators/disable-validation-if-control-is-true';

const cylinderSizeInsuredControls = () => ({
  insured: new FormControl(false),
  duration_months: new FormControl(1),
  name: new FormControl(null),
});

@Component({
  selector: 'app-already-insured-bike',
  templateUrl: './already-insured-bike.component.html',
  styleUrls: ['./already-insured-bike.component.scss']
})
export class AlreadyInsuredBikeComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() label: string;

  public selectableInsurances: Array<string>;

  @Input('insurances')
  set insurances(value: Array<string>) {
    if (value.length === 0) {
      return;
    }
    this.selectableInsurances = value;
  }

  public CYLINDER_SPECIFIC_DISPLAY_MAPPING = [
    {
      key: 'cylinder_80_to_125',
      display: '80 à 125 cm3',
    },
    {
      key: 'cylinder_126_to_399',
      display: '126 à 399 cm3',
    },
    {
      key: 'cylinder_400_to_599',
      display: '400 à 599 cm3',
    },
    {
      key: 'cylinder_600_and_more',
      display: '600 cm3 et plus',
    },
  ];



  public alreadyInsuredFormControls = {
    already_insured: new FormControl(false, [Validators.required]),
    cylinder_80_to_125: new FormGroup(cylinderSizeInsuredControls()),
    cylinder_126_to_399: new FormGroup(cylinderSizeInsuredControls()),
    cylinder_400_to_599: new FormGroup(cylinderSizeInsuredControls()),
    cylinder_600_and_more: new FormGroup(cylinderSizeInsuredControls()),
  };


  ngOnInit() {
    Object.keys(this.alreadyInsuredFormControls).forEach(controlName => {
      this.form.addControl(controlName, this.alreadyInsuredFormControls[controlName]);
    });
    disableValidationIfControlIsFalse(this.form, 'already_insured');
    this.form.controls.already_insured.updateValueAndValidity();
  }

  sliderDisplayFunction = (value: number) => {
    return value;
  }

}
