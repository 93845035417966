import { Injectable } from '@angular/core';
import Color from 'color';
@Injectable({
  providedIn: 'root'
})
export class ColorsService {

  constructor() { }

  public colorAtIndex(index: number, steps: number, colorStart: Color, colorEnd: Color) {
    if (steps <= 1) {
      return colorStart.rgb().string();
    }
    return Color({
      r: (colorStart.color[0] * (steps - 1 - index) / (steps - 1) + colorEnd.color[0] * index / (steps - 1)),
      g: (colorStart.color[1] * (steps - 1 - index) / (steps - 1) + colorEnd.color[1] * index / (steps - 1)),
      b: (colorStart.color[2] * (steps - 1 - index) / (steps - 1) + colorEnd.color[2] * index / (steps - 1))
    }).rgb().string();

  }
}
