import { Component, OnInit, ViewChild, AfterContentChecked } from '@angular/core';
import { BrokersService } from '../../services/brokers.service';
import { Broker } from './brokers';
import { MatPaginator, MatTableDataSource, PageEvent } from '@angular/material';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private brokersService: BrokersService ) { }
  @ViewChild(MatPaginator, {static:  false}) paginator: MatPaginator;

  private allFetchedBrokers: {[pageIndex: number]: Array<Broker>} = {};

  public totalBrokers: number;
  private lastOffset = 0;
  private lastPageSize = 10;
  public displayedColumns = ['id', 'insurerName', 'date', 'lastName', 'firstName'];
  dataSource = new MatTableDataSource<Broker>();

  ngOnInit() {
    this.brokersService.getHistory(this.lastPageSize, this.lastOffset).subscribe(brokersResponse => {
      this.dataSource.data = brokersResponse.brokers;
      this.totalBrokers = brokersResponse.count;
    });


  }
  updateDataSource(pageEvent: PageEvent) {
    if (pageEvent.pageSize !== this.lastPageSize) {
      this.allFetchedBrokers = {};
    }

    if (!(Object.keys(this.allFetchedBrokers)).includes(pageEvent.pageIndex.toString())) {
      this.brokersService.getHistory(pageEvent.pageSize, pageEvent.pageIndex).subscribe((brokersResponse) => {
        this.allFetchedBrokers[pageEvent.pageIndex] = brokersResponse.brokers;
        this.dataSource.data = brokersResponse.brokers;
        this.totalBrokers = brokersResponse.count;
      });
    } else {
      this.dataSource.data = this.allFetchedBrokers[pageEvent.pageIndex];
    }

    this.lastPageSize = pageEvent.pageSize;
  }

}
