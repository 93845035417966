import { Component, OnInit } from '@angular/core';
import { faAt, faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent implements OnInit {
  public faPhone = faPhone;
  public faAt = faAt;
  constructor() { }

  ngOnInit() {
  }

}
