import { Component, Input, OnInit } from '@angular/core';
import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ModelInfo } from 'src/app/shared/models/models';
import { ModelsService } from '../../services/models.service';

@Component({
  selector: 'app-model-availability',
  templateUrl: './model-availability.component.html',
  styleUrls: ['./model-availability.component.scss']
})
export class ModelAvailabilityComponent implements OnInit {


  // tslint:disable-next-line: variable-name
  public insurers: Array<string>;
  public informationIcon = faInfoCircle;
  public warningIcon = faExclamationCircle;

  @Input()
  set modelInfo(modelInfo: ModelInfo) {
    this.modelsService.getInsurersForModel(modelInfo).subscribe(insurers => {
      this.insurers = insurers;
    });
  }

  constructor(private modelsService: ModelsService) { }

  ngOnInit() {
  }

}


