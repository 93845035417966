import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { disableValidationIfControlIsFalse } from '../../validators/disable-validation-if-control-is-true';

@Component({
  selector: 'app-contract-termination',
  templateUrl: './contract-termination.component.html',
  styleUrls: ['./contract-termination.component.scss']
})
export class ContractTerminationComponent implements OnInit {

  @Input() form: FormGroup;

  public contractTerminationFormControls = {
    had_contract_termination: new FormControl(false),
    reason: new FormControl(null, [Validators.required]),
    date: new FormControl(null, [Validators.required]),
  };


  ngOnInit() {
    Object.keys(this.contractTerminationFormControls).forEach(controlName => {
      this.form.addControl(controlName, this.contractTerminationFormControls[controlName]);
    });

    disableValidationIfControlIsFalse(this.form, 'had_contract_termination');
    this.form.controls.had_contract_termination.updateValueAndValidity();
  }
}
