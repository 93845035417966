

export class BrokerFromApi {
    'broker_id': string;
    'insurer_name': string;
    'user_first_name': string;
    'user_last_name': string;
    'created_at': Date;
}

export class BrokerResponse {
    brokers: Array<BrokerFromApi>;
    count: number;
}

export class Broker {
    id: string;
    insurer: string;
    userFirstName: string;
    userLastName: string;
    creationDate: Date;

    constructor(brokerFromApi: BrokerFromApi) {
        this.id = brokerFromApi.broker_id;
        this.insurer = brokerFromApi.insurer_name;
        this.userFirstName = brokerFromApi.user_first_name;
        this.userLastName = brokerFromApi.user_last_name;
        this.creationDate = brokerFromApi.created_at;
    }
}
