import { Component, OnInit } from '@angular/core';
import { blogItems } from '../../blog-items';

@Component({
  selector: 'app-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.scss']
})
export class FrontPageComponent implements OnInit {

  constructor() { }

  public indexes = blogItems;

  ngOnInit() {
  }

}
