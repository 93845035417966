import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
const TOKEN_KEY = 'jwt_token';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private localStorage: LocalStorageService) {}

  public getToken(): string {
    return this.localStorage.get(TOKEN_KEY);
  }

  public setToken(token: string): void {
    return this.localStorage.set(TOKEN_KEY, token);
  }

  public tokenExists(): boolean {
    return this.localStorage.keyExists(TOKEN_KEY);
  }
}
