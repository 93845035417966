import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InsurersService {

  constructor(private http: HttpClient) { }

  getCarInsurers() {
    return this.http.get(`${environment.api}insurers/car`).pipe(
      map((responseObject: InsurersResponseObject) => responseObject.insurers)
    );
  }

  getBikeInsurers() {
    return this.http.get(`${environment.api}insurers/bike`).pipe(
      map((responseObject: InsurersResponseObject) => responseObject.insurers)
    );
  }
}
