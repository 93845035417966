import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const laterThanToday = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        const controlDate = new Date(control.value);

        if (controlDate.valueOf() < now.valueOf()) {
            return {notValidated: true};
        } else {
            return;
        }
    };
};
