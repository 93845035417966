import { Component, OnInit } from '@angular/core';
import { faBalanceScale, faInfo, faNewspaper, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import { Router } from '@angular/router';

type FooterItem = ({
  content: string,
  link: string,
  isImage: boolean
});

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  constructor(private router: Router) { }
  public sections: Array <{
    icon: IconDefinition,
    name: string,
    items: Array<FooterItem>
  }> = [
    {
      name: 'Nos partenaires',
      icon: faHandshake,
      items: [
        {
          content: 'april_banner.png',
          link: 'https://www.april-moto.com/',
          isImage: true
        },
        {
          content: 'xenassur.png',
          link: 'https://www.xenassur.fr/',
          isImage: true
        },
        {
          content: 'fma.png',
          link: 'https://fma.fr/',
          isImage: true
        },
        {
          content: 'mutuelle.jpg',
          link: 'https://www.mutuelledesmotards.fr/',
          isImage: true
        },
        {
          content: 'eurodommages.jpg',
          link: 'https://www.eurodommages.fr/',
          isImage: true
        },
        {
          content: 'netvox.png',
          link: 'https://www.netvox-assurances.fr/',
          isImage: true
        }
      ]
    },
    {
      name: 'Informations',
      icon: faInfo,
      items: [
        {
          content: 'Qui sommes-nous',
          link: '/presentation',
          isImage: false
        },
        {
          content: 'Contact',
          link: '/presentation',
          isImage: false
        },
        {
          content: 'Foire aux questions',
          link: '/faq',
          isImage: false
        }
      ]
    },
    {
      name: 'Suivez-nous !',
      icon: faNewspaper,
      items: [
        {
          content: 'facebook.png',
          link: 'https://www.facebook.com/AssuranceMotoAS2R/',
          isImage: true
        },
        {
          content: 'instagram.png',
          link: 'https://www.instagram.com/',
          isImage: true
        },
        {
          content: 'linkedin.png',
          link: 'https://fr.linkedin.com/',
          isImage: true
        },
      ]
    },
    {
      name: 'Informations réglementaires',
      icon: faBalanceScale,
      items: [
        {
          content: 'Mentions légales',
          link: '/legal',
          isImage: false
        },
        {
          content: 'CGU',
          link: '/cgu',
          isImage: false
        },
      ]
    }
  ];
  ngOnInit() {
  }

  public onImageClicked(link: string) {
    window.location.href = link;
  }
}
