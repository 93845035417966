import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  public get(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  public set(key: string, value: unknown) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public delete(key: string) {
    localStorage.removeItem(key);
  }

  public keyExists(key: string) {
    return (this.get(key) === null ? false : true);
  }

}
