import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { BlogRoutingModule } from './blog-routing.module';
import { CancelContractComponent } from './components/cancel-contract/cancel-contract.component';
import { ChoosingGearComponent } from './components/choosing-gear/choosing-gear.component';
import { CritairComponent } from './components/critair/critair.component';
import { DriveBetterComponent } from './components/drive-better/drive-better.component';
import { FrontPageComponent } from './components/front-page/front-page.component';
import { GroupComponent } from './components/group/group.component';
import { PassengerComponent } from './components/passenger/passenger.component';
import { ReformComponent } from './components/reform/reform.component';
import { SideCarComponent } from './components/side-car/side-car.component';
import { TogetherComponent } from './components/together/together.component';
import { TouringComponent } from './components/touring/touring.component';
import { TrajectoryComponent } from './components/trajectory/trajectory.component';
import { VirtualSignatureComponent } from './components/virtual-signature/virtual-signature.component';
import { WhichLicenseComponent } from './components/which-license/which-license.component';
import { BlogComponent } from './page/blog/blog.component';

const BLOG_PAGES = [
  FrontPageComponent,
  CritairComponent,
  ChoosingGearComponent,
  VirtualSignatureComponent,
  CancelContractComponent,
  TouringComponent,
  TogetherComponent,
  DriveBetterComponent,
  GroupComponent,
  TrajectoryComponent,
  PassengerComponent,
  SideCarComponent,
  WhichLicenseComponent,
  ReformComponent,
];

@NgModule({
  declarations: [
    BlogComponent,
    ...BLOG_PAGES,
  ],
  imports: [
    CommonModule,
    BlogRoutingModule,
    SharedModule,
  ],
  exports: [
    BlogComponent,
  ]
})
export class BlogModule { }
