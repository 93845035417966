import { merge, of } from 'rxjs';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';

export const disableValidationIfControlIsFalse =  (formGroup: FormGroup, controlName: string) => {
  formGroup.controls[controlName].valueChanges.subscribe(enable => {
    if (enable === true) {
      Object.keys(formGroup.controls).forEach(control => {
        if (control !== controlName) {
          formGroup.controls[control].enable({emitEvent: false});
        }
      });
    } else {
      Object.keys(formGroup.controls).forEach(control => {
        if (control !== controlName) {
          formGroup.controls[control].disable({emitEvent: false});
        }
      });
    }
  });
};

export const disableValidationForSingeControlIfControlIsFalse =  (disabled: FormControl, disabler: FormControl) => {
  disabler.valueChanges.subscribe(enable => {
    if (enable === true) {
      disabled.enable({emitEvent: false});
    } else {
      disabled.disable({emitEvent: false});
    }
  });
};

export const disableValidationIfControlsAreFalse =  (disabled: FormGroup, disablers: Array<FormControl>) => {
  merge(...disablers.map((disabler) => disabler.valueChanges)).subscribe( value => {
    for (const disabler of disablers) {
      if (disabler.value === false) {
        disabled.disable({emitEvent: false});
        if (Object.values(disabled.controls).includes(disabler)) {
          disabler.enable({emitEvent: false});
        }
        return;
      }
    }
    disabled.enable({emitEvent: false});
  });
};


export const disableValidationIfExternalControlIsFalse =  (formGroup: FormGroup, control: FormControl) => {
  control.valueChanges.subscribe(enable => {
    if (enable === true) {
      Object.keys(formGroup.controls).forEach(controlName => {
        formGroup.controls[controlName].enable({emitEvent: false});
      });
    } else {
      Object.keys(formGroup.controls).forEach(controlName => {
        formGroup.controls[controlName].disable({emitEvent: false});
      });
    }
  });
};
