import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-which-license',
  templateUrl: './which-license.component.html',
  styleUrls: ['./which-license.component.scss', '../blog-style.scss']
})
export class WhichLicenseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
