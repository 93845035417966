import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { FormService } from 'src/app/modules/comparator/services/form.service';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { ImageDialogComponent } from './components/image-dialog/image-dialog.component';
import { Exception } from './services/exception/exception';
import { ExceptionsService } from './services/exceptions.service';

@Component({
  selector: 'app-exceptions',
  templateUrl: './exceptions.component.html',
  styleUrls: ['./exceptions.component.scss']
})
export class ExceptionsComponent implements OnInit {

  public exceptions: Array<Exception>;
  public displayTable = false;
  constructor(
    private exceptionService: ExceptionsService,
    private dialog: MatDialog,
    private formService: FormService,
    private snackBar: SnackBarService,
    ) { }

  public displayedColumns = ['id', 'url', 'date', 'image', 'context', 'actions'];

  ngOnInit() {
    this.exceptionService.getExceptions().subscribe(exceptions => {
      this.exceptions = exceptions;
      this.displayTable = true;
    });
  }

  public onImageViewClick(element: Exception) {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: {imageId: element.image, json: element.context}
    });
  }

  public onApplyClick(element: Exception) {
    this.formService.submit(element.context).pipe(
      catchError((err, caught) => {
        return of(caught);
      }),
      switchMap( () => this.exceptionService.deleteException(element.id).pipe(
        switchMap( () => this.exceptionService.getExceptions().pipe(
          tap(exceptions => {
            this.exceptions = exceptions;
          })
        ))
      ))
    ).subscribe();

  }

  public onDeleteClick(element: Exception) {
    this.exceptionService.deleteException(element.id).pipe(
      switchMap(_ => this.exceptionService.getExceptions().pipe(
        tap(exceptions => {
          this.exceptions = exceptions;
        })
      ))
    ).subscribe();
  }

}
