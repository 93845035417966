import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancel-contract',
  templateUrl: './cancel-contract.component.html',
  styleUrls: ['./cancel-contract.component.scss', '../blog-style.scss']
})
export class CancelContractComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
