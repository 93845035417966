import { CityService } from './city.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss'],
})
export class CityComponent implements OnInit {
  @Input() control: FormControl;
  @Input() description: string;
  city = '';
  constructor(private cityService: CityService) {}

  ngOnInit() {
    this.control.valueChanges.subscribe((postalCode: string) => {
      if (this.control.valid) {
        this.cityService
          .getCityFromPostalCode(postalCode)
          .subscribe((city) => (this.city = city));
      }
    });
  }
}
