import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComplaintsComponent } from './page/complaints/complaints.component';

@NgModule({
  declarations: [ComplaintsComponent],
  imports: [
    CommonModule
  ],
  exports: [
    ComplaintsComponent,
  ]
})
export class ComplaintsModule { }
