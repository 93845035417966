import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-licence',
  templateUrl: './licence.component.html',
  styleUrls: ['./licence.component.scss'],
})
export class LicenceComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() licenseName: string;
  @Input() subtitle: string;

  ngOnInit() {
    this.form.addControl('obtained', new FormControl(false, []));
    this.updateDateValidator(false);

    this.form.get('obtained').valueChanges.subscribe((obtained) => {
      // this.form.patchValue(
      //   { [this.formItem]: this.currentFormGroup.value }
      // );
      this.updateDateValidator(obtained);
    });
  }

  private updateDateValidator(obtained: boolean) {
    if (obtained) {
      this.form.addControl(
        'date',
        new FormControl(null, [Validators.required])
      );
    } else {
      this.form.removeControl('date');
    }
  }
}
