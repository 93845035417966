import { ExceptionResponseObject } from '../exception.response-object.ts/exception.response-object';

export class Exception {
    id: number;
    url: string;
    date: Date;
    image: string;
    context: object;

    constructor(exceptionRO: ExceptionResponseObject) {
        Object.assign(this, {
            id: exceptionRO.id,
            url: exceptionRO.url,
            date: new Date(exceptionRO.created_at),
            image: exceptionRO.exception_image,
            context: exceptionRO.exception_context
        });
    }
}
