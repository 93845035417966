import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drive-better',
  templateUrl: './drive-better.component.html',
  styleUrls: ['./drive-better.component.scss', '../blog-style.scss']
})
export class DriveBetterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
