import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { QuotationComponent } from './quotation.component';

@NgModule({
  declarations: [QuotationComponent],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    QuotationComponent
  ]
})
export class QuotationModule { }
