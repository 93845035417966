import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { disableValidationIfControlIsFalse } from '../../validators/disable-validation-if-control-is-true';

@Component({
  selector: 'app-already-insured',
  templateUrl: './already-insured.component.html',
  styleUrls: ['./already-insured.component.scss']
})
export class AlreadyInsuredComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() label: string;

  public selectableInsurances: Array<string>;

  @Input('insurances')
  set insurances(value: Array<string>) {
    if (value.length === 0) {
      return;
    }
    this.selectableInsurances = value;
  }

  public alreadyInsuredFormControls = {
    already_insured: new FormControl(false, [Validators.required]),
    name: new FormControl(null, [Validators.required]),
    duration_months: new FormControl(0, [Validators.required]),
  };



  ngOnInit() {
    Object.keys(this.alreadyInsuredFormControls).forEach(controlName => {
      this.form.addControl(controlName, this.alreadyInsuredFormControls[controlName]);
    });
    disableValidationIfControlIsFalse(this.form, 'already_insured');
    this.form.controls.already_insured.updateValueAndValidity();
  }



}
