import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { PresentationComponent } from './page/presentation/presentation.component';

@NgModule({
  declarations: [PresentationComponent],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    PresentationComponent,
  ]
})
export class PresentationModule { }
