import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ExceptionResponseObject } from './exception.response-object.ts/exception.response-object';
import { Exception } from './exception/exception';

@Injectable({
  providedIn: 'root'
})
export class ExceptionsService {
  constructor(private http: HttpClient) { }

  getExceptions() {
    return this.http.get(`${environment.api}exceptions/exceptions/`).pipe(
      map((responseObject: Array<ExceptionResponseObject>) => responseObject.map((exceptionRO) => {
        return new Exception(exceptionRO);
      }))
    );
  }

  deleteException(id: number) {
    return this.http.delete(`${environment.api}exceptions/exceptions/${id}/`);
  }

}
