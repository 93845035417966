import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

const MOBILE_SIZE_THRESHOLD = 800;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('navOpenClose', [
      transition(':enter', [
        style({opacity: 0}),
        animate(250, style({opacity: 1}))
      ]),
      transition(':leave', [
        animate(250, style({opacity: 0}))
      ])
    ])
  ]
})




export class NavbarComponent implements OnInit {

  public phoneIcon = faBars;
  public mobileMenuShown = false;
  public mobileMode = false;

  public redirect(pagename: string) {
    if('not-avaiable' === pagename){
      Swal.fire({
        title: 'Momentanément Indisponible!',
        html: '<div>CONTACTER NOUS<br/>' +
          '\n' +
          'Tél : 09 87 04 65 69<br/>' +
          '\n' +
          '<a>contact@as2r.com</a></div>',
        icon: 'warning',
        confirmButtonText: 'Fermer',
        confirmButtonColor: "#be4831",
      })

    } else {
      document.location.href = pagename;
    }
  }

  public pictograms = [
    {name: 'Moto', imageName: 'Moto color.png',        url: 'https://tarif-devis.amgestionassurance.com/fr/form/site/moto?cle=lVGXhqaC8JJhhQrjTLaQhU2rN_SkJmZM1PxVvuIeni39k-QNQChLdlHnSPz2Ha5G-_9jloHF7ZWBify_do1FPw=='},
    {name: 'Scooter', imageName: 'Scooter color.png',  url: 'https://tarif-devis.amgestionassurance.com/fr/form/site/scooter?cle=lVGXhqaC8JJhhQrjTLaQhU2rN_SkJmZM1PxVvuIeni39k-QNQChLdlHnSPz2Ha5G-_9jloHF7ZWBify_do1FPw=='},
    {name: 'Cyclo', imageName: 'scooter-50 color.png', url: 'https://tarif-devis.amgestionassurance.com/fr/form/site/50?cle=lVGXhqaC8JJhhQrjTLaQhU2rN_SkJmZM1PxVvuIeni39k-QNQChLdlHnSPz2Ha5G-_9jloHF7ZWBify_do1FPw=='},
    {name: 'Quad', imageName: 'Quad color .png',       url: 'https://tarif-devis.amgestionassurance.com/fr/form/site/quad?cle=lVGXhqaC8JJhhQrjTLaQhU2rN_SkJmZM1PxVvuIeni39k-QNQChLdlHnSPz2Ha5G-_9jloHF7ZWBify_do1FPw=='},
    {name: 'Piste', imageName: 'moto piste color.png', url: 'not-avaiable'},
    {name: 'Verte', imageName: 'Moto verte color.png', url: 'https://tarif-devis.amgestionassurance.com/fr/form/site/verte?cle=lVGXhqaC8JJhhQrjTLaQhU2rN_SkJmZM1PxVvuIeni39k-QNQChLdlHnSPz2Ha5G-_9jloHF7ZWBify_do1FPw=='},
    {name: 'Collection', imageName: 'moto collection.png', url: 'https://tarif-devis.amgestionassurance.com/fr/form/site/verte?cle=lVGXhqaC8JJhhQrjTLaQhU2rN_SkJmZM1PxVvuIeni39k-QNQChLdlHnSPz2Ha5G-_9jloHF7ZWBify_do1FPw=='},
  ];

  constructor() { }

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.mobileMode = event.target.innerWidth < MOBILE_SIZE_THRESHOLD;
  }

  @HostListener('window:scroll', ['$event'])
  private onScroll(event) {
    this.mobileMenuShown = false;
  }

  public closeNavIfMobile() {
    if (this.mobileMode) {
      this.mobileMenuShown = false;
    }
  }

}
