import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-list-select',
  templateUrl: './list-select.component.html',
  styleUrls: ['./list-select.component.scss']
})
export class ListSelectComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() formItem: string;
  @Input() choices: Array<string>;
  @Input() labels: Array<string> = this.choices;

  public listForm: FormGroup = new FormGroup({});

  constructor() { }

  ngOnInit() {
    this.choices.forEach((choice) => {
      this.listForm.addControl(choice, new FormControl(false));
    });
    this.listForm.valueChanges.subscribe(() => {
      this.form.patchValue({
        [this.formItem]: this.listForm.value
      });
    });
  }
}
