import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Color from 'color';
import { map } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { environment } from 'src/environments/environment';
import { APIPriceResponseObject, Insurance, InsurerStaticInformation } from '../../../shared/models/price-object';

@Injectable({
  providedIn: 'root'
})
export class PricesService {

  public localStorageBodyKey = 'body';
  public requested = false;
  private insurerInformation: Array<InsurerStaticInformation> = [
    {
      label: 'April Moto',
      baseUrl: `${environment.api}april`,
      logoPath: 'assets/april_banner.png',
      colorStart: Color('#94B932'),
      colorEnd: Color('#F99E1C'),
      documentsUrl: 'https://www.april-moto.com/documents-utiles/'
    },
    {
      label: 'FMA Assurances',
      baseUrl: `${environment.api}fma`,
      logoPath: 'assets/fma.png',
      colorStart: Color('#575656'),
      colorEnd: Color('#E07C45'),
      documentsUrl: 'https://www.fma.fr/documents-fma-assurances-telechargement.aspx'
    },
    {
      label: 'Netvox',
      baseUrl: `${environment.api}netvox`,
      logoPath: 'assets/netvox_banner.png',
      colorStart: Color('#847173'),
      colorEnd: Color('#76B628'),
      documentsUrl: 'https://www.netvox-assurances.fr/courtier/vos-offres/'
    }
  ];
  public priceObjects: Array<Insurance>;

  constructor(
    private http: HttpClient,
    private local: LocalStorageService
    ) { }


  public savePriceRequestInfo(formBody: object): void {
    this.requested = true;
    this.priceObjects = [];
    this.local.set(this.localStorageBodyKey, formBody);
    this.insurerInformation.forEach(priceObject => {
      this.priceObjects.push ( {
        ...priceObject,
        priceRequest: this.http.post(`${priceObject.baseUrl}/get-pricing`, formBody).pipe(
          map((apiPriceResponse: APIPriceResponseObject) => apiPriceResponse)
        )
      });
    });
  }
}

