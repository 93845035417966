import { FormGroup } from '@angular/forms';

export const oneOptionTrueValidator = (group: FormGroup): {[key: string]: boolean} => {
    for (const control of Object.keys(group.controls)) {
        if (group.value[control]) {
            return;
        }
    }
    return {allValueFalse: true};
  };
