import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: [
    './complaints.component.scss',
    '../../../terms-and-conditions/page/terms-and-conditions/terms-and-conditions.component.scss'
  ]
})
export class ComplaintsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
