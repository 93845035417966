import Color from 'color';
import { Observable } from 'rxjs';

export class InsurerStaticInformation {
    baseUrl: string;
    label: string;
    logoPath: string;
    colorStart: Color;
    colorEnd: Color;
    documentsUrl: string;
}

export class Insurance extends InsurerStaticInformation {
    priceRequest: Observable<APIPriceResponseObject>;
    priceObject?: InsurancePriceInformation;
    error?: string;
}

export class APIPriceResponseObject {
    offers: Array<APIOffer>;
    'options_labels': Array<APIOptionLabel>;
    'guarantees_labels': Array<APIGuaranteesLabel>;
    'quotation_context': APIQuotationContext ;
}
export class APIQuotationContext {
    [key: string]: string;
}

class APIGuaranteeLabel {
    text: string;
    description: string;
}

class APIGuaranteesLabel {
    text: string;
    description: string;
}
class APIOptionLabel {
    text: string;
    'value_labels': [{
        description: string,
        name: string,
        id: string,
    }];
}

class APIOptionValue {
    name: string;
    description: string;
    'price_month': number;
    'price_year': number;
}

class APIGuarantee {
    name: string;
    included: boolean;
}

class APIOption {
    name: string;
    included: boolean;
    values: Array<APIOptionValue>;
}

export class APIOffer {
        name: string;
        prices: {
            price_month: number,
            price_year: number
        };
        guarantees: Array<APIGuarantee>;
        options: Array<APIOption>;


}
const formatPrice = (price: number) => price;

class OptionValue {
    name: string;
    description: string;
    priceMonth: number;
    priceYear: number;

    constructor(apiValue: APIOptionValue) {
        this.name = apiValue.name;
        this.description = apiValue.description;
        this.priceMonth = apiValue.price_month;
        this.priceYear = apiValue.price_year;
    }
}

class Guarantee {
    name: string;
    included: boolean;

    constructor(guaranteeFromApi: APIGuarantee) {
        this.name = guaranteeFromApi.name;
        this.included = guaranteeFromApi.included;
    }
}

export class Option {
    name: string;
    included: boolean;
    values: Array<OptionValue> = [];
    private valueNameToValue: {[valueName: string]: OptionValue} = {};

    constructor(optionsFromAPI: APIOption) {
        this.name = optionsFromAPI.name;
        this.included = optionsFromAPI.included;
        for (const value of optionsFromAPI.values) {
            const newValue = new OptionValue(value);
            this.values.push(newValue);
            this.valueNameToValue[newValue.name] = newValue;
        }
        this.values = optionsFromAPI.values.map(apiValue => new OptionValue(apiValue));
    }

    public getValueByName(name: string) {
        return this.values.find(value => value.name === name);
    }
}

export class Offer {
    name: string;
    priceMonth: number;
    priceYear: number;
    options: Array<Option> = [];
    guarantees: Array<Guarantee> = [];
    private optionNameToOption: {[optionName: string]: Option} = {};
    private guaranteeNameToGuarantee: {[guaranteeName: string]: Guarantee} = {};

    constructor(offerFromApi: APIOffer) {
        this.name = offerFromApi.name;
        this.priceMonth = formatPrice(offerFromApi.prices.price_month);
        this.priceYear = formatPrice(offerFromApi.prices.price_year);
        for (const optionFromApi of offerFromApi.options) {
            const newOption = new Option(optionFromApi);
            this.options.push(newOption);
            this.optionNameToOption[newOption.name] = newOption;
        }
        for (const guaranteeFromApi of offerFromApi.guarantees) {
            const newGuarantee = new Guarantee(guaranteeFromApi);
            this.guarantees.push(newGuarantee);
            this.guaranteeNameToGuarantee[newGuarantee.name] = newGuarantee;
        }
    }

    public getOptionByOptionName(optionName: string) {
        return this.optionNameToOption[optionName];
    }

    public getGuaranteeByGuaranteeName(guaranteeName: string) {
        return this.guaranteeNameToGuarantee[guaranteeName];
    }
}

class GuaranteeLabel {
    text: string;
    description: string;

    constructor(apiGuaranteeLabel: APIGuaranteeLabel) {
        this.text = apiGuaranteeLabel.text;
        this.description = apiGuaranteeLabel.description;
    }
}
export class OptionLabel {
    text: string;
    valueLabels: Array<ValueLabel> = [];
    private valueLabelToValue: {[valueLabelName: string]: ValueLabel} = {};

    constructor(apiOptionLabel: APIOptionLabel) {
        this.text = apiOptionLabel.text;
        for (const apiValueLabel of apiOptionLabel.value_labels) {

            this.valueLabels.push (apiValueLabel);
            this.valueLabelToValue[apiValueLabel.name] = apiValueLabel;
        }
    }

    public getValueFromValueLabel(valueLabel: string) {
        return this.valueLabelToValue[valueLabel];
    }

}

class ValueLabel {
    description: string;
    name: string;
    id: string;
}


export class InsurancePriceInformation {
    offers: Array<Offer>;
    guaranteesLabels: Array<GuaranteeLabel>;
    optionsLabels: Array<OptionLabel> = [];
    quotationContext: APIQuotationContext;

    private optionNameToOptionLabel: {[optionName: string]: OptionLabel} = {};

    constructor(insurerFromApi: APIPriceResponseObject) {
        this.offers = insurerFromApi.offers.map(offerFromApi => new Offer(offerFromApi));
        this.guaranteesLabels = insurerFromApi.guarantees_labels.map(guaranteeLabel => new GuaranteeLabel(guaranteeLabel));
        for (const apiOptionLabel of insurerFromApi.options_labels) {
            const newOptionLabel = new  OptionLabel(apiOptionLabel);
            this.optionsLabels.push(newOptionLabel);
            this.optionNameToOptionLabel[newOptionLabel.text] = newOptionLabel;
        }
        this.quotationContext = insurerFromApi.quotation_context;
    }

    public getOptionLabelFromOptionName(optionName: string) {
        return this.optionNameToOptionLabel[optionName];
    }
}

