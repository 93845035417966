import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-bonus',
  templateUrl: './bonus.component.html',
  styleUrls: ['./bonus.component.scss']
})
export class BonusComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() label: string;

  public bonusFormControls = {
    bonus_value: new FormControl(100, [Validators.required]),
    bonus_at_05_for_more_than_three_years: new FormControl(false),
  };

  constructor() { }

  ngOnInit() {
    Object.keys(this.bonusFormControls).forEach(controlName => {
      this.form.addControl(controlName, this.bonusFormControls[controlName]);
    });
  }

  sliderDisplayFunction = (value: number) => {
    return value / 100;
  }
}
