import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'app-snackbar-prompt',
  templateUrl: './snackbar-prompt.component.html',
  styleUrls: ['./snackbar-prompt.component.scss']
})
export class SnackbarPromptComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<SnackbarPromptComponent>, ) {}

  public onYes() {
    this.snackBarRef.dismissWithAction();
  }

  public onNo() {
    this.snackBarRef.dismiss();
  }


}
