import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { disableValidationIfControlIsFalse } from '../../validators/disable-validation-if-control-is-true';
import { oneOptionTrueValidator } from '../../validators/one-option-true.validator';

@Component({
  selector: 'app-license-suspension',
  templateUrl: './license-suspension.component.html',
  styleUrls: ['./license-suspension.component.scss']
})
export class LicenseSuspensionComponent implements OnInit {

  @Input() form: FormGroup;

  public suspensionReasons = {
    Alcool: 'alcohol',
    Stupéfiants: 'drugs',
    'Délit de fuite': 'hit_and_run',
    Autres: 'other'
  };


  public licenseSuspensionFormControls = {
    been_suspended: new FormControl(false, [Validators.required]),
    type: new FormControl(0, [Validators.required]),
    duration_months: new FormControl(0, [Validators.required]),
    date: new FormControl(null, [Validators.required]),
    reason: new FormGroup({
      alcohol: new FormControl(false),
      drugs: new FormControl(false),
      hit_and_run: new FormControl(false),
      other: new FormControl(false),
    }, {validators: oneOptionTrueValidator}),
  };



  private subscription: Subscription;

  constructor() { }

  ngOnInit() {
    Object.keys(this.licenseSuspensionFormControls).forEach(controlName => {
      this.form.addControl(controlName, this.licenseSuspensionFormControls[controlName]);
    });
    disableValidationIfControlIsFalse(this.form, 'been_suspended');
    this.form.controls.been_suspended.updateValueAndValidity();
  }



}
