import {  disableValidationIfControlsAreFalse } from './../../validators/disable-validation-if-control-is-true';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { disableValidationIfControlIsFalse } from '../../validators/disable-validation-if-control-is-true';

@Component({
  selector: 'app-hamon',
  templateUrl: './hamon.component.html',
  styleUrls: ['./hamon.component.scss']
})
export class HamonComponent implements OnInit {
  @Input() label: string;
  @Input() form: FormGroup;
  @Input() isInsuredBikeControl: FormControl;

  public blabla = false;
  public hamonFormControls = {
    is_hamon: new FormControl(false, [Validators.required]),
    address1: new FormControl('', [Validators.required]),
    address2: new FormControl(''),
    postal_code: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    contract_number: new FormControl('', [Validators.required]),
  };


  ngOnInit() {
    Object.keys(this.hamonFormControls).forEach(controlName => {
      this.form.addControl(controlName, this.hamonFormControls[controlName]);
    });
    // disableValidationIfExternalControlIsFalse(this.form, this.isInsuredBikeControl);
    // disableValidationIfControlIsFalse(this.form, 'is_hamon');
    disableValidationIfControlsAreFalse(this.form, [this.isInsuredBikeControl, this.form.get('is_hamon') as FormControl]);
    this.form.controls.is_hamon.updateValueAndValidity();
  }

}
