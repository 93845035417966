import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { BrokerFromApi, Broker, BrokerResponse } from 'src/app/modules/admin/pages/dashboard/brokers';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BrokersService {

  constructor(private http: HttpClient, private router: Router, private snackbar: SnackBarService) { }

  public getHistory(count: number, offset: number) {
    return this.http.get(`${environment.api}brokers`, {params: {count: count.toString(), offset: offset.toString()}}).pipe(
      catchError(err => {
        this.snackbar.error('Veuillez vous reconnecter');
        this.router.navigateByUrl('/admin/login');
        return throwError(err);
      }),
      map((brokerResponse: BrokerResponse) => {
        return {
          count: brokerResponse.count,
          brokers: brokerResponse.brokers.map(brokerFromApi => new Broker(brokerFromApi))
        };
      }),
    );
  }
}
