import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { SnackbarPromptComponent } from 'src/app/shared/components/snackbar-prompt/snackbar-prompt.component';


@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar, ) { }

  public error(message: string) {
    this.openSnackBar(message, 'error');
  }

  public success(message: string) {
    this.openSnackBar(message, 'success');
  }

  public prompt(message: string) {
    const config = new MatSnackBarConfig();
    config.duration = 5000;
    return this.snackBar.openFromComponent(SnackbarPromptComponent, {
      data: {
        message,
      }
    });
  }

  private openSnackBar(message: string, htmlClass: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = [htmlClass];
    config.duration = 5000;
    this.snackBar.open(message, undefined, config);
  }
}
