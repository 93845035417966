import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() formItem: string;
  @Input() choices: Array<boolean | string | number> = [true, false];
  @Input() labels: Array<boolean | string | number> = this.choices;

  private choiceIndex = 0;
  public currentLabel: boolean | string | number;

  ngOnInit(): void {
    // Initialize the toggle to the default formcontrol value
    if (this.form.value[this.formItem] !== undefined && this.choices.includes(this.form.value[this.formItem])) {
      this.choiceIndex = this.choices.indexOf(this.form.value[this.formItem]);
    }
    this.form.patchValue({ [this.formItem]: this.choices[this.choiceIndex] });
    this.updateLabel();
  }

  public toggleValue(): void {
    this.choiceIndex++;
    if (this.choiceIndex === this.choices.length) {
      this.choiceIndex = 0;
    }
    // Update value
    this.form.patchValue({ [this.formItem]: this.choices[this.choiceIndex] });
    // Update label
    this.updateLabel();
  }

  private updateLabel(): void {
    this.currentLabel = this.labels[this.choiceIndex];
  }
}
