import { CancelContractComponent } from './components/cancel-contract/cancel-contract.component';
import { ChoosingGearComponent } from './components/choosing-gear/choosing-gear.component';
import { CritairComponent } from './components/critair/critair.component';
import { DriveBetterComponent } from './components/drive-better/drive-better.component';
import { GroupComponent } from './components/group/group.component';
import { PassengerComponent } from './components/passenger/passenger.component';
import { ReformComponent } from './components/reform/reform.component';
import { SideCarComponent } from './components/side-car/side-car.component';
import { TogetherComponent } from './components/together/together.component';
import { TouringComponent } from './components/touring/touring.component';
import { TrajectoryComponent } from './components/trajectory/trajectory.component';
import { VirtualSignatureComponent } from './components/virtual-signature/virtual-signature.component';
import { WhichLicenseComponent } from './components/which-license/which-license.component';

export const blogItems: {name: string, route: string, image: string, component: any, imageOffsetPercent?: number}[] = [
    {
      name: 'Comment resilier votre contrat d’assurance moto, scooter, quad ?',
      route: 'resilier',
      image: 'cancel-contract.jpg',
      component: CancelContractComponent
    },
    {
      name: 'Grand tourisme à moto',
      route: 'grand-tourisme',
      image: 'touring.jpg',
      component: TouringComponent
    },
    {
      name: 'Rouler à deux à moto',
      route: 'a-deux',
      image: 'together.jpg',
      component: TogetherComponent
    },
    {
      name: 'Mieux conduire à moto',
      route: 'mieux-conduire',
      image: 'drive-better.jpg',
      component: DriveBetterComponent
    },
    {
      name: 'Conduire en groupe à moto',
      route: 'en-groupe',
      image: 'group.jpg',
      component: GroupComponent
    },
    {
      name: 'La trajectoire de sécurité',
      route: 'trajectoire',
      image: 'trajectory.jpg',
      imageOffsetPercent: 40,
      component: TrajectoryComponent
    },
    {
      name: 'Être un bon passager',
      route: 'passager',
      image: 'passenger.jpg',
      component: PassengerComponent
    },
    {
      name: 'Rouler avec un side-car',
      route: 'side-car',
      image: 'side-car.jpg',
      component: SideCarComponent
    },
    {
      name: 'Quel permis pour quel vehicule ?',
      route: 'quel-permis',
      image: 'which-license.jpg',
      imageOffsetPercent: 30,
      component: WhichLicenseComponent
    },
    {
      name: 'Mieux comprendre la réforme du permis Moto A2 en 2020',
      route: 'reforme',
      image: 'reform.jpg',
      imageOffsetPercent: 10,
      component: ReformComponent
    },
    {
      name: 'La signature électronique : un service 100% dématérialisé',
      route: 'signature-electronique',
      image: 'virtual-signature.jpg',
      imageOffsetPercent: 20,
      component: VirtualSignatureComponent
    },
    {
      name: 'Vignette crit’air : tout ce qu’il faut savoir pour votre 2-roues',
      route: 'critair',
      image: 'critair.jpg',
      component: CritairComponent
    },
    {
      name: 'Choisir son équipement motard',
      route: 'equipement',
      image: 'choosing-gear.jpg',
      component: ChoosingGearComponent
    },
  ];
