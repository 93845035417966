


export class QuotationFromApi {
    quotation: DocumentFromApi;
    information: DocumentFromApi;
}

class DocumentFromApi {
    'file_transfered_as': 'base64'|'url';
    file: Base64File | UrlFile;
}

export class Document {
    fileTransferedAs: 'base64'|'url';
    file: Base64File | UrlFile;
    fileName: string;

    constructor(documentFromApi: DocumentFromApi) {
        this.fileTransferedAs = documentFromApi.file_transfered_as;
        this.file = documentFromApi.file;
        this.fileName = documentFromApi.file.name;
    }

    public generate() {
        if (this.fileTransferedAs === 'base64') {
            this.generateFromBase64();
        } else if (this.fileTransferedAs === 'url') {
            this.generateFromUrl();
        }
    }

    private generateFromBase64() {
        const downloadLink = document.createElement('a');

        downloadLink.href = `data:application/pdf;base64, ${(this.file as Base64File).data}`;
        downloadLink.download = this.fileName;
        downloadLink.click();
    }

    private generateFromUrl() {
        window.open((this.file as UrlFile).url);
    }

}

class Base64File {
    name: string;
    data: string;
}

class UrlFile {
    name: string;
    url: string;
}

export class Quotation {
    quotation: Document;
    information: Document;
    constructor(quotationFromApi: QuotationFromApi) {
        this.quotation = new Document(quotationFromApi.quotation);
        this.information = new Document(quotationFromApi.information);
    }
}
