import { TypesResponseObject } from './../../../../../shared/models/models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// tslint:disable-next-line: max-line-length
import { BrandsResponseObject, CylinderSizesResponseObject, ModelInfo, ModelInfoResponseObject, ModelsResponseObject } from 'src/app/shared/models/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModelsService {

  constructor(private http: HttpClient) {}

  getTypes() {
    return this.http.get(`${environment.api}models/types/`).pipe(
      map((types: TypesResponseObject) => types.types));
  }

  private addVehicleTypeIfDefined(vehicleType: string) {
    return vehicleType !== undefined ? `${vehicleType}/` : '';
  }

  getBrands(vehicleType?: string) {
    return this.http.get(`${environment.api}models/brands/${this.addVehicleTypeIfDefined(vehicleType)}`).pipe(
      map((brands: BrandsResponseObject) => brands.brands));
  }

  getCylinderSizes(brand: string, vehicleType?: string) {
    return this.http.get(`${environment.api}models/cylinder-sizes/${this.addVehicleTypeIfDefined(vehicleType)}${brand}/`).pipe(
      map((sizes: CylinderSizesResponseObject) => sizes.sizes));
  }

  getModels(brand: string, cylinderSize: string, vehicleType?: string) {
    return this.http.get(`${environment.api}models/model/${this.addVehicleTypeIfDefined(vehicleType)}${brand}/${cylinderSize}/`).pipe(
      map((models: ModelsResponseObject) => models));
  }

  getModelInfoFromSRA(sra: string) {
    return this.http.get(`${environment.api}models/from-sra/${sra}/`).pipe(
      map((modelInfo: ModelInfoResponseObject) => modelInfo)
    );
  }

  getInsurersForModel(modelInfo: ModelInfo): Observable<Array<string>> {
    const params = new HttpParams()
      .set('brand_name', modelInfo.brand_name)
      .set('cylinder_size', modelInfo.cylinder_size.toString())
      .set('model_name', modelInfo.model_name);
    return this.http.get(`${environment.api}models/get-insurers`, {params}).pipe(
      map((insurersRO: InsurersResponseObject) => insurersRO.insurers)
    );
  }
}
